import Layout from "hocs/layout/Layout"


function Error404(){
    return(
        <Layout>
            Error404
        </Layout>
    )
}


export default Error404