import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom"
import {AnimatePresence} from 'framer-motion'
import Error404 from "containers/errors/Error404"
import Home from "containers/pages/Home"
import Dashboard from "containers/pages/dashboard"
import ResetPassword from "containers/auth/ResetPassword"
import ResetPasswordConfirm from "containers/auth/ResetPasswordConfirm"
import EditPost from "containers/pages/Blog/EditPost"
import Blog from "containers/pages/Blog/Blog"



function Animated(){
    const location = useLocation()
    return(
        <Routes location={location} key={location.pathname}>
            {/*Error Dsiplay*/}
            <Route path="*" element={<Error404/>}/> 
            {/*Home Disply*/}
            <Route path="/" element={<Home/>}/> 
            <Route path="/forgot_password" element={<ResetPassword/>}/>
            <Route path="/password/reset/confirm/:uid/:token" element={<ResetPasswordConfirm/>}/>
            <Route path="/blog" element={<Blog/>}/>
            <Route path="/blog/:post_id" element={<EditPost/>}/>
            <Route path="/dashboard" element={<Dashboard/>}/>
             
             
        </Routes>      
    )
}
export default Animated